<template>
  <div>
    <h1>页面结构<small>(类似页面左右区域)</small></h1>
    <div class="box">
      <div class="g-layout">
        <div
          class="g-layout-l"
          style="background: red; color: #000; font-size: 30px"
        >
          left(可以互相调整方向)
        </div>
        <div class="g-layout-r"
          style="background: blue; color: #000; font-size: 30px"
        >
          right
          <br />
          <p>文件路径：common.scss</p>
          <p>组件名：g-layout</p> 
        </div>
      </div>
    </div>
    
    <hr />
    <h1>通用组件<small>(组件: 卡片类似的独立组件)</small></h1>
    <div class="box">
			<div></div>

		</div>
    <hr />
    <h1>元件<small>(按钮 标签 类似的小元件)</small></h1>
    <div class="box"></div>
    <hr />
  </div>
</template>

<script>
import Clipboard from "clipboard";

export default {
  name: "",
  data() {
    return {};
  },
  methods: { 
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.box {
  padding: 40px;
}
pre {
	position: absolute;top:0;
	left: -10000px;
}
</style>